import * as React from 'react';
import { Link } from 'gatsby';

// markup
const NotFoundPage = () => {
  return (
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <span className="o-accent-line mx-auto"></span>
        </div>
        <div className="max-w-xl mx-auto py-4 sm:py-4">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
              404 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              The page you are looking for could not be found.
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">
              Popular Pages
            </h2>
            <ul
              role="list"
              className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                    <svg
                      className="h-6 w-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                      />
                    </svg>
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                    <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <a href="/explore" className="focus:outline-none">
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                        Explore Content
                      </a>
                    </span>
                  </h3>
                  <p className="text-base text-gray-500">
                    Take a tour through research, best practices, and more{' '}
                  </p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </li>
              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                    <svg
                      className="h-6 w-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      />
                    </svg>
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                    <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <a
                        href="https://airtable.com/shral2kRQj9MBTBiS"
                        className="focus:outline-none"
                      >
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                        Contribute Content
                      </a>
                    </span>
                  </h3>
                  <p className="text-base text-gray-500">
                    Neighbour Institute is a place to learn and share
                  </p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </li>
            </ul>
            <div className="mt-8 mb-20">
              <a
                href="/"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
              >
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
